//scope constants
var $d,
  $d = $(document),
  contactActive = false,
  activeSite,
  swapping = false,
  sortcats = ["", ""],
  idleTime = 0,
  controls = true;

$(function () {
  if ($(".showcase").length > 0) {
    $(".showcase .pic").first().show(0).addClass("is-active");

    if ($(".showcase .pic").length <= 1) {
      $(".controls .button.left").hide(0);
      $(".controls .button.right").hide(0);
    }

    var idleInterval = setInterval(timerIncrement, 1000);
  }

  if ($(".project").length > 0) {
    filterProjects();
  }

  $(this).on("mousemove", function (e) {
    idleTime = 0;
    if (!controls) {
      controls = true;
      $(".controls").fadeIn(200);
    }
  });
  $(this).on("keypress", function (e) {
    idleTime = 0;
    if (!controls) {
      controls = true;
      $(".controls").fadeIn(200);
    }
  });
});

// CLICK EVENTS

$d.on("click.cat", ".navMain .nav a", function (e) {
  e.preventDefault();
  var cat = $(this).data("cat");

  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");

    if ($(this).hasClass("cat1")) {
      sortcats[0] = "";
    } else if ($(this).hasClass("cat2")) {
      sortcats[1] = "";
    }
  } else {
    if ($(this).hasClass("cat1")) {
      $(".cat1").removeClass("is-active");
      sortcats[0] = cat;
    } else if ($(this).hasClass("cat2")) {
      $(".cat2").removeClass("is-active");
      sortcats[1] = cat;
    }

    $(this).addClass("is-active");
    $(".project").addClass("hidden");
    $(".project." + cat).removeClass("hidden");
  }

  filterProjects();
})
  .on("click.contact", ".navTop a.contact", function (e) {
    e.preventDefault();

    if (contactActive) {
      contactActive = false;
      $(".contactwrap")
        .stop()
        .slideUp(400, function () {
          $("header").removeClass("contactOpen");
          $(".navTop a.contact").removeClass("is-active");
        });

      $(".controls").fadeIn(400);
      $(".contactcloser").hide(0);

      activeSite.addClass("is-active");
    } else {
      contactActive = true;
      $(".contactwrap").stop().slideDown(500, "easeOutCubic");
      $("header").addClass("contactOpen");
      activeSite = $(".navTop a.is-active");
      activeSite.removeClass("is-active");

      $(".controls").fadeOut(400);
      $(".contactcloser").show(0);

      $(this).addClass("is-active");
    }
  })
  .on("click.nextpic", ".controls .button.right", function (e) {
    e.preventDefault();
    swapPic(1);
  })
  .on("click.prevpic", ".controls .button.left", function (e) {
    e.preventDefault();
    swapPic(-1);
  })
  .on("keydown", function (e) {
    if (e.which == 37) {
      swapPic(-1);
    } else if (e.which == 39) {
      swapPic(1);
    }
  })
  .on("click.contactcloser", ".contactcloser", function (e) {
    e.preventDefault();

    if (contactActive) {
      contactActive = false;
      $(".contactwrap")
        .stop()
        .slideUp(400, function () {
          $("header").removeClass("contactOpen");
          $(".navTop a.contact").removeClass("is-active");
        });

      $(".controls").fadeIn(400);
      $(".contactcloser").hide(0);

      activeSite.addClass("is-active");
    }
  })
  .on("click.clearfilter", ".clearfilter", function (e) {
    $(".cat1").removeClass("is-active");
    $(".cat2").removeClass("is-active");
    sortcats = ["", ""];
    filterProjects();
  })
  .on("touchstart.gallery", ".showcase", function (e) {
    idleTime = 0;
    if (!controls) {
      controls = true;
      $(".controls").fadeIn(200);
    }
  });

// FUNCTIONS

function filterProjects() {
  $(".project").each(function () {
    $this = $(this);
    $this.addClass("hidden");
    if (sortcats[0] != "" && sortcats[1] != "") {
      if ($this.hasClass(sortcats[0]) && $this.hasClass(sortcats[1])) {
        $this.removeClass("hidden");
      }
    }
    if (sortcats[0] == "" && sortcats[1] != "") {
      if ($this.hasClass(sortcats[1])) {
        $this.removeClass("hidden");
      }
    }
    if (sortcats[0] != "" && sortcats[1] == "") {
      if ($this.hasClass(sortcats[0])) {
        $this.removeClass("hidden");
      }
    }
    if (sortcats[0] == "" && sortcats[1] == "") {
      $this.removeClass("hidden");
    }
  });
}

function swapPic(isNext) {
  idleTime = 0;
  if (!controls) {
    controls = true;
    $(".controls").fadeIn(200);
  }
  if (!swapping && $(".showcase .pic").length > 1) {
    swapping = true;
    var $activePic = $(".showcase .pic.is-active");
    if (isNext == 1) {
      var $nextPic = $activePic.next();
      if ($activePic.index() >= $(".showcase .pic").length - 1) {
        var $nextPic = $(".showcase .pic").first();
      }
    } else {
      var $nextPic = $activePic.prev();
      if ($activePic.index() <= 0) {
        var $nextPic = $(".showcase .pic").last();
      }
    }

    $activePic
      .removeClass("is-active")
      .animate(
        { left: isNext * -1 * 100 + "%" },
        800,
        "easeOutCubic",
        function () {
          $(this).hide(0);
          swapping = false;
        }
      );
    $nextPic
      .addClass("is-active")
      .show(0)
      .css("left", isNext * 100 + "%")
      .animate({ left: "0" }, 800, "easeOutCubic");
  }
}

function timerIncrement() {
  idleTime++;
  if (idleTime > 2 && controls) {
    // 20 minutes
    controls = false;
    $(".controls").fadeOut(500);
  }
}
